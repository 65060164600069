<template>
    <div :style="`padding:5px;width:100%;margin-top:60px !important;direction:${$parent.lang.dir}`" class="mt-10">
        <div style="display:inline-flex;padding:0 5px" v-for="(tab,index) of tabs" :key="index">
            <b-button v-if="tab.class == 'mytab' && tab.style == '' && checkIf(index)" style="width:140px;background:#000 !important;font-weight:bold;font-size:.8rem" :class="`btn-sm btn `+tab.smclass" @click='goTo(tab.href,tab.main)'>{{tab.name}}  <i class="fas fa-arrow-down"></i></b-button>
            <b-button v-else-if="tab.class == 'mytab' && tab.style != '' && checkIf(index)" :style="`background:#000 !important;width:140px;font-weight:bold;font-size:.8rem`+tab.style" :class="`btn-sm btn `+tab.smclass" @click='goTo(tab.href,tab.main)'>{{tab.name}}  <i class="fas fa-arrow-down"></i></b-button>
            <b-button v-else-if="checkIf(index)" :class="`btn-sm btn `+tab.smclass" :style="`width:140px;background:#eee !important;color:black !important;font-weight:bold;font-size:.8rem`+tab.style" @click='goTo(tab.href,tab.main)'>{{tab.name}} </b-button>
        </div>
    </div>
</template>
<script>

export default{
    props: [
      'tabs'
    ],
    methods: {
        checkIf(index){
          for(let i=0; i< this.tabs[index].perms.length;i++){
            
            if(this.tabs[index].perms[i] == 'any') return true;

            if(this.$store.state.licenseType[this.tabs[index].perms[i]]){
              return true;
            }
          }
          return false
        },
        goTo(path,main){
            localStorage.setItem("currentMenu",main);
            this.$router.push({path: path})
        },
        checkLink(){
          let t = false;
          let path = '';
          for(let i=0;i<this.tabs.length;i++){
            if(this.$route.path == this.tabs[i].href){
              t = true
            }
            if(i == 0){
              path = this.tabs[i].href;
            }
          }
          // // console.log("tt",t);
          if(!t){
            this.$router.push({path: path})
          } 
        }
    },
    created() {
      setTimeout(() => {this.checkLink()},1000)
      
    },
}
</script>

<style>
.mytab{
  width:100%;
  font-size:.8rem;
  border:0px solid #fff;
}
.mytab2{
  width:100%;
  background:rgb(4, 112, 155) !important;
  font-size:.8rem;
  color:#FFF;
  border:0px solid #fff;
}
.mytab4{
  width:100%;
  font-size:.8rem;
  background:rgb(15, 90, 230) !important;
  border:0px solid #fff;
}
.mytab3{
  width:100%;
  font-size:.8rem;
  background:#198754 !important;
  border:0px solid #fff;
}
.mytab1{
  width:100%;
  font-size:.8rem;
  background:#fe7b33 !important;
  border:0px solid #fff;
}
.mytab5{
  width:100%;
  font-size:.8rem;
  background:#339cfe !important;
  border:0px solid #fff;
}
.mytab6{
  width:100%;
  font-size:.8rem;
  background:#fe3344 !important;
  border:0px solid #fff;
}
.font-small{
  font-size:.8em !important;
  width:120px !important;
}
</style>
