<template>
    <div class="topDiv">
        <!-- <div class="iconsbtn" :style="lang.lalgin+`:10px`">
            <i class="fas fa-print" @click="einit(), printme(0)" style="color:blue" :title="lang.print_page"></i>
            <i class="fas fa-file-excel" @click="einit(), printme(2)" style="color:green" :title="lang.export_excel"></i>
            <i class="fas fa-file-pdf" @click="einit(), printme(1)" style="color:red" :title="lang.print_pagexport_pdfe"></i>
        </div> -->
        <div class="iconsbtn" :style="lang.lalgin+`:10px;direction:rtl`">
            <v-btn class="exportBtn exportWats" v-b-toggle.send_general_whats style="width:110px;font-size:.5rem; margin-inline-end: 10px;"><img class="exportWats" style="width:116px;" :src="origin +`/whatsappicon.png`" /></v-btn>
            <v-btn class="exportBtn exportPrint" @click="doExport(0)" style="width:110px;font-size:.4rem;">{{lang.print}}</v-btn>
            <v-btn class="exportBtn exportExcel" @click="doExport(2)" style="width:110px;font-size:.4rem;">{{lang.export_excel}}</v-btn>
            <v-btn class="exportBtn exportPDF" @click="doExport(1)" style="width:110px;font-size:.4rem;">{{lang.print_pagexport_pdfe}}</v-btn>
        </div>
        <sendGeneralWhats ref="sendGeneralWhats" />
    </div>
</template>

<script>
import axios from 'axios'
import sendGeneralWhats from '@/components/sendGeneralWhats.vue'
export default{
    components: {sendGeneralWhats},
    data() {
        return {
            sdate: '',
            edate: '',
            exportdata:[],
            exporttitle:[],
            palte_number: '',
            contact_number: '',
            settings: '',
            origin: window.location.origin
        }
    },
    computed:{
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                    return this.$store.state.lang.en;
                }else{
                    return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        }
    },
    created(){
        this.getSettings();
    },
    methods: {
        getSettings()
        {
            const post = new FormData();
            post.append("type",'getSettings');
            post.append("auth",this.$cookies.get(this.$COOKIEPhase));
            post.append("data[all]",'all');
            axios.post(
                this.$SAMCOTEC.r_path,post
            ).then(
                (response) => {
                    const res = response.data;
                    this.settings = res.results.data;
                }
            )
        },
        whatsapp(){
            const mobile = this.settings.whatsapp.substring(1);
            window.open('https://wa.me/966'+mobile,"_blank");
        },
        doExport(id){
            this.$parent.preparedata();
            // einit();
            this.printme(id)
        },
        // einit() {
        //     this.$parent.preparedata();
        // },
        printDreport(){
          let pdfFrame = document.body.appendChild(document.createElement('iframe'));
          pdfFrame.style.display = 'none';
          pdfFrame.onload = () => (void pdfFrame.contentWindow.print());
          pdfFrame.src = '../api/exprint.php?sdate='+this.sdate+'&edate='+this.edate;
        },
        printme(id){
            const post = new FormData();
            post.append('type',"startexport");
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('lang',this.$cookies.get(this.$store.state.LangCooki));
            post.append('data[main][sdate]',this.sdate);
            post.append('data[main][edate]',this.edate);
            post.append('data[main][exporttitle]',this.exportdata.title);
            post.append('data[main][list]',this.exportdata.list);
            post.append('data[main][palte_number]',this.palte_number);
            post.append('data[main][contact_number]',this.contact_number);
            // console.log(this.exportdata.data);
            for(let i=0;i<this.exportdata.data.length;i++){
                
                post.append('data[data]['+i+']',btoa(unescape(encodeURIComponent(JSON.stringify(this.exportdata.data[i])))))
                
            }
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post,
                {
                    maxBodyLength: Infinity,
                    maxContentLength: Infinity,
                }
            ).then((response) =>{
                const res = response.data;
                // console.log(res);
                if(id==0){
                    let pdfFrame = document.body.appendChild(document.createElement('iframe'));
                    pdfFrame.style.display = 'none';
                    pdfFrame.onload = () => (void pdfFrame.contentWindow.print());
                    pdfFrame.src = window.location.origin + '/api/exprint.php?expdata='+res.results.data.newID;
                }else if(id==1){
                    window.open(window.location.origin  + '/api/exprint.php?expdata='+res.results.data.newID,"_blank")
                }
                else if(id==2){
                    window.open(window.location.origin  + '/api/exprint.php?excel&expdata='+res.results.data.newID,"_blank")
                }
            });
        }
    },
}
</script>
<style>
.topDiv{
    position: relative;
}
.iconsbtn{
    position: absolute;
    top:3px;
}
.iconsbtn i{
    margin-inline-end: 5px;
    font-size: 1.5em;
    cursor: pointer;
}
.exportBtn{
    border:none !important;
    box-shadow:none !important;
}
.exportBtn:active, .exportBtn:hover{
    border:none !important;
    box-shadow:none !important;
}
.exportPrint,.exportPrint:hover{
    background:darkblue !important;
    color:#fff !important;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    margin-inline-end: 5px;
}
.exportExcel,.exportExcel:hover{
    background:darkgreen !important;
    color:#fff !important;
    border-left: 2px solid #fff !important;
    border-right: 2px solid #fff !important;
    border-radius: 0;
    margin-inline-end: 5px;
}
.exportPDF,.exportPDF:hover{
    background:red !important;
    color:#fff !important;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    
}
.exportWats{
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}
</style>