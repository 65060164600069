<template>
    <div>
        <b-sidebar backdrop id="password_users" width="50rem" aria-labelledby="sidebar-no-header-title" no-header style="direction:ltr;" right :title="formtitle" shadow >
            <template #default="{ hide }">
            <div class="m-1 text-start" style="direction:rtl">
                <div style="width:120px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                    <span>{{formtitle}}</span>
                </div>
                <div @click="hide" id="closeChangePassword" style="float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
                    <span>{{$parent.lang.close}}</span>
                </div>
            </div>
            <v-divider></v-divider>
            <div class="px-3 py-2" style="direction:rtl">
                <v-row>
                    <v-col cols="4">
                        <label>{{$parent.lang.username}}</label>
                        <b-form-input class="inborder"
                            :label="$parent.lang.username + ` *`"
                            v-model="user.username"
                            readonly
                            />
                    </v-col>
                    <v-col cols="4">
                        <label>{{$parent.lang.full_name}}</label>
                        <b-form-input class="inborder"
                            :label="$parent.lang.full_name + ` *`"
                            v-model="user.full_name"
                            />
                    </v-col>
                    <v-col cols="4">
                        <label>{{$parent.lang.oldpassword}}</label>
                        <b-form-input class="inborder"
                            :label="$parent.lang.oldpassword + ` *`"
                            v-model="user.oldpassword"
                            type="password"
                            ></b-form-input>
                    </v-col>
                    <v-col cols="4">
                        <label>{{$parent.lang.newpassword}}</label>
                        <b-form-input class="inborder"
                            :label="$parent.lang.newpassword + ` *`"
                            v-model="user.password"
                            type="password"
                            />
                    </v-col>
                
                </v-row>
            </div>
    </template>
            <template #footer="" class="shadow">
                <div class="d-flex text-light align-items-center px-3 py-2">
                <b-button type="button" variant="success" @click='addUser()' class="ma-2" style="width:100px;">{{ SUBMIT_NAME }}</b-button>
                </div>
            </template>
        </b-sidebar>
        <vue-snotify></vue-snotify>
    </div>
</template>

<script>
import {SnotifyPosition, SnotifyStyle} from 'vue-snotify';
import axios from 'axios';
export default {
    data() {
        return {
            perms:[
                {
                    text: this.$parent.lang.cashier,
                    value: 3,
                },
                {
                    text: this.$parent.lang.accountant,
                    value: 2,
                },
                {
                    text: this.$parent.lang.administratior,
                    value: 1,
                },
                {
                    text: this.$parent.lang.vendors,
                    value: 4,
                },
                {
                    text: this.$parent.lang.salesman,
                    value: 5,
                },
            ],
            permissions:{
                text: this.$parent.lang.cashier,
                value: 3,
            },
            formtitle: this.$parent.lang.update_user,
            SUBMIT_NAME: this.$parent.lang.update,
            employees: [],
            user:{
                id: 0,
                username: '', 
                full_name: '', 
                password: '', 
                group_id: 0, 
                empid: 0,
                vperc: 0,
                oldpassword: '',
            }
        }
    },
    methods: {
        getUsers(){
            const post = new FormData();
            post.append("type" , 'getUsers');
            post.append("auth",this.$cookies.get(this.$COOKIEPhase));
            post.append('data[userid]',"current");
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then(
                (response) => {
                    const res = response.data;
                    this.user = res.results.data.results[0];
                    this.user.password = '';
                    this.user.oldpassword = '';
                    this.group_id = res.results.data.results[0].group_id;
                }
            )
        },
        resetValue(item){
        
            this.user = {
                id: item.id,
                username: item.username, 
                full_name: item.full_name, 
                password: '', 
                oldpassword: '',
                group_id: parseInt(this.group_id), 
                empid: item.empid,
                vperc: item.vperc,
            }
        },
        userfullname(){
            this.user.full_name = this.employees[this.user.empid];
        },
        getEmp(){
            const post = new FormData();
            post.append("type","getEmpl");
            post.append("auth",this.$cookies.get(this.$COOKIEPhase));
            post.append("data[all]","all");
            axios.post(
                this.$SAMCOTEC.r_path , post
            )
            .then((res) => {
                // // console.log(res);
                this.employees = res.data.results.data;
            });
        },
        resetValues(){
            this.user = {
                id: 0,
                username: '', 
                full_name: '', 
                password: '', 
                group_id: 0, 
                created_by: 0, 
                created_date: '', 
                updated_by: 0, 
                updated_date: '', 
                company_id: 1, 
                branch_id: 1, 
                last_login_date: '', 
                laste_session_update: '', 
                status: 1,
                vperc: 0
            }
        },
        addUser(){
            if (this.$snotify.notifications.length > 0) {
                this.$snotify.notifications.forEach(notification => {
                    this.$snotify.remove(notification.id)
                });
            }
            if(this.user.password == '' || this.user.oldpassword == ''){
                this.$snotify.error('كلمة المرور الجديدة والقديمة لا يمكن ان تكون فارغة', 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }

            if(this.user.full_name == ''){
                this.$snotify.error('الاسم الكامل مطلوب دائما', 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
            const post = new FormData();
            post.append("type",'updateMe');
            post.append("auth",this.$cookies.get(this.$COOKIEPhase));
            post.append("data[id]",this.user.id);
            post.append("data[full_name]",this.user.full_name);
            post.append("data[password]",this.user.password);
            post.append("data[oldpassword]",this.user.oldpassword);
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then(
                (resposnse) => {
                    const res = resposnse.data;
                    // console.log(res)
                    if(res.error.number == 200){
                        this.$snotify.error('تمت العملية بنجاح', 'تنبيه', {
                            timeout: 1000000,
                            showProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            position: SnotifyPosition.centerCenter,
                            buttons: [
                                {
                                    text: this.$parent.lang.close, 
                                    action: (toast) => {
                                        this.$snotify.remove(toast.id); 
                                    } 
                                },
                            ]
                        });
                        this.user.oldpassword = '';
                        this.user.password = '';
                        document.getElementById('closeChangePassword').click();
                    }else{
                        this.$snotify.error('كلمة المرور القديمة غير صحيحة', 'تنبيه', {
                            timeout: 1000000,
                            showProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            position: SnotifyPosition.centerCenter,
                            buttons: [
                                {
                                    text: this.$parent.lang.close, 
                                    action: (toast) => {
                                        this.$snotify.remove(toast.id); 
                                    } 
                                },
                            ]
                        });
                    }
                }
            )
        }
    },
    created() {
       
       this.getUsers();
    },
}
</script>