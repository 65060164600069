<template>
    
    <div class="p-12" :style="`display:none;padding:12px;background:#ccc;margin-bottom:10px;direction:`+$parent.lang.dir">
        <a role="button" v-b-toggle.menu_bar class="myback">
            <i :class="`fas fa-arrow-` + $parent.lang.algin"></i>
            {{$parent.lang.main_menu}} </a>
        <b-link class="mybread" @click="goBack(items.to)">
            {{items.text}}  &nbsp;
        <i class="fas fa-arrow-down"></i>
        </b-link> 
    </div>

</template>
<script>
export default{
    props:['items'],
    created() {
        //// // console.log("items ",this.items);
    },
    methods: {
        goBack(to){
            if(typeof to !== 'undefined' && to != '')
                this.$router.push({path: to})
            
            // const url = this.$route.path;
            // if(url.includes('/view-card/')){
            //     this.$router.push({name: 'Home'})
            // }
            // else if(url.includes('/view-invoice/')){
            //     this.$router.push({name: 'InvoiceList'})
            // }
            // else if(url.includes('/view-quot/')){
            //     this.$router.push({name: 'quotations'})
            // }
        }
    },

}
</script>
<style>
.myback{
    align-items: center;
    text-decoration:none;
    color:#000 !important;
    padding:5px 35px;
    background:#f1901a;
    border-radius:5px;
    max-width: 100px;
}
.mybread{
    margin-inline-start: 15px !important;
    align-items: center;
    text-decoration:none;
    color:#fff !important;
    padding:5px 50px;
    background:green;
    border-radius:5px;
    max-width: 100px;
}
</style>