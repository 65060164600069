<template>
  <div id="Footer" style='z-index: 1000' v-if="$store.state.group_id != 9">
    <small> 2006-{{ new Date().getFullYear() }} All Rights Reserved to SAMCOTEC

      <img :src="`${publicPath}img/samcotec-logo.png`" style="height:20px;margin-top:5px;float:left">
      <div style="float:right;right:10px;display:flex">
          <div>{{$store.state.SystemName}} V {{$store.state.Version}}</div>
          <div v-if="$store.state.licenseType.demoVersion == false" style="margin:-3px 20px 0 20px;cursor: pointer;color:#fff;background:red;padding:5px;" :title="lang.updateprofile" v-b-toggle.password_users @click="Update()">
              {{lang.changePassword}} {{user.full_name}}
          </div>
          
      </div>
    </small>
    <change-password ref="changePassword" />
    <vue-snotify></vue-snotify>
    <!-- <img id="ecarlogo" :src="`${publicPath}img/small-logo-light.png`" style="width:60px;float:right;margin-right:10px;"> -->
  </div>
</template>
<script>
import changePassword from '@/components/changePassword.vue'
import axios from 'axios'

export default {
  components: {changePassword},
  data() {
    return {
      publicPath: window.location.origin+process.env.BASE_URL,
      user: {},
    }
  },
  computed: {
    lang: function(){
          if(this.$cookies.get(this.$store.state.LangCooki) != null){
              if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
              }else{
                return this.$store.state.lang.ar;
              }
          }
          else if(this.$store.state.deflang == 'en'){
              return this.$store.state.lang.en;
          }else{
              return this.$store.state.lang.ar;
          }
      },
  },
  methods: {
    Update(){
      // console.log();
    },
    getUsers(){
        const post = new FormData();
        post.append("type" , 'getUsers');
        post.append("auth",this.$cookies.get(this.$COOKIEPhase));
        post.append('data[userid]','current');
        axios.post(
            this.$SAMCOTEC.r_path, post
        ).then(
            (response) => {
                const res = response.data;
                // // console.log(res);
                this.user = res.results.data.results[0];
            }
        )
    },
  },
  created() {
    this.getUsers();
  }
}
</script>
<style>
#Footer{
  position: fixed;
  bottom: 0;
  left:0;
  right:0;
  height: 35px;
  background: #e9e9e9;
  border-top:1px solid #ccc;
  padding:5px;
  text-align: center;
}
</style>